import { useSearchParams } from "@solidjs/router";
import { LoginScreen } from "~/screens/LoginScreen";

const LogIn = () => {
  const [params] = useSearchParams();

  return <LoginScreen invite={params.invite as string | undefined} />;
};

export default LogIn;
